import React, { FC } from 'react';
import {
  Dialog,
  Text,
  TextTypography,
  Button,
  ButtonPriority,
} from 'wix-ui-tpa/cssVars';
import {
  TFunction,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import {
  Experiment,
  ModalType,
  ProfilePrivacyStatus,
} from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { useBi } from '../../../../../hooks/useBi';
import { useMember, useMemberActions } from '../../../../../contexts/widget';
import { classes, st } from './ProfilePrivacyModal.st.css';

const getPrimaryCtaText = (
  t: TFunction,
  isPublicProfile: boolean,
  useNewContent: boolean,
) => {
  if (useNewContent) {
    return isPublicProfile
      ? t('app.widget.modals.profile-privacy.make-private-action')
      : t('app.widget.modals.profile-privacy.make-public-action');
  }

  return t('app.widget.modals.profile-privacy.primary-action');
};

export const ProfilePrivacyModal: FC<ModalType> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const { privacyStatus } = useMember();
  const { makeProfilePrivate, makeProfilePublic } = useMemberActions();
  const biLogger = useBi();

  const useNewContent = experiments.enabled(
    Experiment.UseNewPrivacyModalContent,
  );
  const isPublicProfile = privacyStatus === ProfilePrivacyStatus.PUBLIC;
  const content = isPublicProfile
    ? t('app.widget.modals.profile-privacy.private.content')
    : t('app.widget.modals.profile-privacy.public.content');
  const title = isPublicProfile
    ? t('app.widget.modals.profile-privacy.private.title')
    : t('app.widget.modals.profile-privacy.public.title');

  const onConfirm = () => {
    if (isPublicProfile) {
      makeProfilePrivate();
      biLogger.leftCommunity();
    } else {
      makeProfilePublic();
      biLogger.joinedCommunity();
    }
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      notFullscreenOnMobile
      className={st(classes.root, { mobile: isMobile })}
      data-hook={DataHook.ProfilePrivacyModal}
      wiredToSiteColors={false}
    >
      <div className={classes.content}>
        <Text
          typography={TextTypography.largeTitle}
          className={classes.title}
          data-hook={DataHook.ProfilePrivacyModalTitle}
        >
          {title}
        </Text>
        <Text
          typography={TextTypography.listText}
          className={classes.textContainer}
          tagName="div"
        >
          {content}
        </Text>
        <div className={classes.ctaContainer}>
          <Button
            upgrade
            priority={ButtonPriority.basicSecondary}
            className={st(classes.button, { secondary: true })}
            onClick={onClose}
            data-hook={DataHook.ProfilePrivacyModalCancelButton}
          >
            {t('app.widget.modals.profile-privacy.secondary-action')}
          </Button>
          <Button
            upgrade
            className={st(classes.button, { primary: true })}
            onClick={onConfirm}
            data-hook={DataHook.ProfilePrivacyModalConfirmButton}
          >
            {getPrimaryCtaText(t, isPublicProfile, useNewContent)}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
