import React, { FC } from 'react';
import {
  Dialog,
  Text,
  TextTypography,
  Button,
  ButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { DataHook } from '../../../../../constants/DataHook';
import { ModalType, BiActionType } from '../../../../../types';
import { useBi } from '../../../../../hooks/useBi';
import { classes, st } from './DiscardChangesModal.st.css';

type Props = {
  onConfirm(): void;
};

export const DiscardChangesModal: FC<ModalType<Props>> = ({
  onClose,
  isOpen,
  additionalProps,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const biLogger = useBi();

  const handleConfirm = () => {
    additionalProps.onConfirm();
    biLogger.ctaButtonClicked(BiActionType.Discard_Changes);
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      notFullscreenOnMobile
      className={st(classes.root, { mobile: isMobile })}
      data-hook={DataHook.DiscardChangesModal}
      forceBWTheme
    >
      <div className={classes.content}>
        <Text
          typography={TextTypography.largeTitle}
          className={classes.titleContainer}
        >
          {t('app.widget.modals.discard-changes.title')}
        </Text>
        <Text
          typography={TextTypography.listText}
          className={classes.textContainer}
          tagName="div"
        >
          {t('app.widget.modals.discard-changes.content')}
        </Text>
        <div className={classes.ctaContainer}>
          <Button
            upgrade
            priority={ButtonPriority.basicSecondary}
            className={st(classes.button, { secondary: true })}
            onClick={onClose}
          >
            {t('app.widget.modals.discard-changes.primary-action')}
          </Button>
          <Button
            upgrade
            className={st(classes.button, { primary: true })}
            onClick={handleConfirm}
          >
            {t('app.widget.modals.discard-changes.secondary-action')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
